import React, { useCallback } from 'react';
import { FaWhatsapp, FaTwitter, FaFacebook } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import { WhatsappShareButton, FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import useCompanyInfo from './useCompanyInfo';
import Cencomer from './Cencomer';
import Logo from './Logo';
import QRCode from 'qrcode.react';
import PayInfoButton from './PayInfoButton';

const Footer = () => {
  const companyInfoData = useCompanyInfo();

  const handleEmailButtonClick = useCallback(() => {
    if (companyInfoData && companyInfoData.eMail) {
      window.location.href = `mailto:${companyInfoData.eMail}`;
    }
  }, [companyInfoData]);

  const handleCallButtonClick = useCallback(() => {
    if (companyInfoData && companyInfoData.telefonoWhatsApp) {
      const callLink = `tel:${companyInfoData.telefonoWhatsApp}`;
      window.location.href = callLink;
    }
  }, [companyInfoData]);

  if (!companyInfoData || !companyInfoData.eMail) {
    return null;
  }

  const {
    eMail,
    textEMail,
    title,
    descripcion,
    telefonoWhatsApp,
    direccion,
    location,
    shortDescription,
    linkPay,
  } = companyInfoData;

  const shareUrl = window.location.href;
  const text = `¡Echa un vistazo a ${title}! - ${shortDescription}`;

  return (
    <footer className="text-center bg-white">
      <div className="text-xs md:flex md:flex-row items-center justify-around py-8">
        <div className="my-8 mx-8 md:w-1/2 md:px-16">
          <div className="flex justify-center items-center space-x-3">
            <Logo size={50} />
            <h2 className="my-2 text-xl font-semibold">{title}</h2>
          </div>
          <p className="py-2 lg:px-8">{descripcion}</p>
          {telefonoWhatsApp && (
            <button
              onClick={handleCallButtonClick}
              className="py-2 text-gray-700 font-semibold hover:no-underline focus:outline-none"
            >
              <p>Teléfono: {telefonoWhatsApp}</p>
            </button>
          )}
          <div className="mb-2">
            <button
              onClick={handleEmailButtonClick}
              className="text-gray-700 font-semibold hover:no-underline focus:outline-none"
            >
              {textEMail}: {eMail}
            </button>
          </div>
          <p>{direccion}</p>
          <p>{location}</p>
        </div>

        {linkPay && (
          <div className="my-8 mx-8">
            <PayInfoButton />
          </div>
        )}

        <div className="md:flex flex-col space-x-2 md:w-1/3 mb-4">
          <div className="flex justify-center mt-8">
            <QRCode value={window.location.protocol + '//' + window.location.hostname} />
          </div>
          <div className="mb-4 mt-4 text-xs font-semibold">Compartir</div>
          <div className="space-x-4">
            <WhatsappShareButton url={shareUrl} title={text} aria-label="Compartir en WhatsApp">
              <FaWhatsapp size={22} className="text-green-500 hover:text-green-600" />
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl} quote={text} aria-label="Compartir en Facebook">
              <FaFacebook size={22} className="text-blue-700 hover:text-blue-800" />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={text} aria-label="Compartir en Twitter">
              <FaTwitter size={22} className="text-blue-400 hover:text-blue-500" />
            </TwitterShareButton>
            <EmailShareButton
              url={shareUrl}
              subject={text}
              body={`Mira este sitio: ${title} - ${shortDescription}`}
              aria-label="Compartir por Email"
            >
              <RiMailSendLine size={22} className="text-gray-600 hover:text-gray-700" />
            </EmailShareButton>
          </div>
        </div>
      </div>
      <div className="mb-12 md:mb-0">
        <Cencomer />
      </div>
    </footer>
  );
};

export default Footer;
