import React, { useState } from "react";
import { CiHome, CiMap, CiShare2 } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { SlInfo, SlWallet } from "react-icons/sl";
import useCompanyInfo from "./useCompanyInfo";
import useApiStyles from './useApiStyles';
import CompanyInfoModal from "./CompanyInfoModal";
import PagosModal from "./PagosModal";

const BottomNavigationBar = () => {
    const companyInfo = useCompanyInfo();
    const [modalType, setModalType] = useState(null);
    const themeColors = useApiStyles();

    const openModal = (type) => setModalType(type);
    const closeModal = () => setModalType(null);

    const navigateToRoot = () => window.location.href = "/";
    
    const startWhatsAppChat = () => {
        if (!companyInfo || !companyInfo.telefonoWhatsApp || !companyInfo.mensajeWhatsApp) {
            alert("Los datos de WhatsApp no están disponibles en la información de la compañía.");
            return;
        }
        
        const sitioWebURL = window.location.href;
        const mensajeConURL = `${companyInfo.mensajeWhatsApp} ${sitioWebURL}`;
        const whatsappURL = `https://api.whatsapp.com/send?phone=${companyInfo.telefonoWhatsApp}&text=${encodeURIComponent(mensajeConURL)}`;

        window.open(whatsappURL, "_blank");
    };

    const openMapLink = () => {
        if (companyInfo && isValidURL(companyInfo.mapLink)) {
            window.open(companyInfo.mapLink, "_blank");
        }
    };

    const isValidURL = (url) => /^(ftp|http|https):\/\/[^ "]+$/.test(url);

    const shareSite = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: "¡Echa un vistazo a este increíble sitio web!",
                url: window.location.href,
            }).catch((error) => console.error("Error al compartir:", error));
        } else {
            alert("La funcionalidad de compartir no es compatible en este navegador.");
        }
    };

    return (
        <div className={`md:hidden fixed bottom-0 left-0 w-full ${themeColors?.background ?? 'bg-gray-900'} shadow-lg rounded-t-lg`} role="navigation" aria-label="Bottom navigation bar">
            <div className={`flex justify-around items-center py-3 ${themeColors?.text ?? 'text-gray-300'}`}>
                <button aria-label="Inicio" className={`flex flex-col items-center ${themeColors?.text ?? 'text-gray-300'}`} onClick={navigateToRoot}>
                    <CiHome size={24} />
                    <span className="text-xs mt-1">Inicio</span>
                </button>
                {companyInfo && isValidURL(companyInfo.mapLink) && (
                    <button aria-label="Mapa" className={`flex flex-col items-center ${themeColors?.text ?? 'text-gray-300'}`} onClick={openMapLink}>
                        <CiMap size={24} />
                        <span className="text-xs mt-1">Mapa</span>
                    </button>
                )}
                {companyInfo && companyInfo.telefonoWhatsApp && companyInfo.mensajeWhatsApp && (
                    <button aria-label="Chat" className={`flex flex-col items-center ${themeColors?.text ?? 'text-gray-300'}`} onClick={startWhatsAppChat}>
                        <FaWhatsapp size={24} className="text-green-500" />
                        <span className="text-xs mt-1">Chat</span>
                    </button>
                )}
                <button aria-label="Info" className={`flex flex-col items-center ${themeColors?.text ?? 'text-gray-300'}`} onClick={() => openModal('info')}>
                    <SlInfo size={24} />
                    <span className="text-xs mt-1">Info</span>
                </button>
                <button aria-label="Pagos" className={`flex flex-col items-center ${themeColors?.text ?? 'text-gray-300'}`} onClick={() => openModal('pagos')}>
                    <SlWallet size={24} />
                    <span className="text-xs mt-1">Pagos</span>
                </button>
                <button aria-label="Compartir" className={`flex flex-col items-center ${themeColors?.text ?? 'text-gray-300'}`} onClick={shareSite}>
                    <CiShare2 size={24} />
                    <span className="text-xs mt-1">Compartir</span>
                </button>
            </div>
            {modalType === 'info' && <CompanyInfoModal isOpen={true} onRequestClose={closeModal} />}
            {modalType === 'pagos' && <PagosModal isOpen={true} onRequestClose={closeModal} />}
        </div>
    );
};

export default React.memo(BottomNavigationBar);
