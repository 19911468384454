// FloatingActionButton.js
import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import WhatsappButtonFlotante from "./WhatsappButtonFlotante";

const FloatingActionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const yOffset = window.scrollY;
    setIsVisible(yOffset > 100);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`items-end flex flex-col fixed bottom-16 md:bottom-4 right-4 ${
        isVisible ? "opacity-100" : "opacity-0"
      } transition-opacity duration-300`}
    >
      <div className="mb-4">
        <WhatsappButtonFlotante />
      </div>
      <button
        className="bg-gray-950 opacity-60 text-white p-2 rounded-full focus:outline-none"
        onClick={scrollToTop}
        aria-label="Scroll to top"
      >
        <FaArrowUp size={24} aria-hidden="true" />
      </button>
    </div>
  );
};

export default FloatingActionButton;
