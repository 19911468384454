import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { SlPhone, SlMap, SlEnvelopeOpen } from 'react-icons/sl';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo

// Función utilitaria para validar correos electrónicos
const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

// Componente de botón reutilizable
const ContactButton = ({ onClick, icon: Icon, text, className }) => (
  <button
    onClick={onClick}
    className={`flex flex-col items-center justify-center w-20 text-center rounded-md bg-white px-2 py-2 leading-6 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 bg-opacity-90`}
  >
    <Icon className="text-3xl mt-1 mb-2 text-gray-800" />
    <div className="text-xs text-gray-800">{text}</div>
  </button>
);

const ContactButtons = () => {
  const companyInfoData = useCompanyInfo();

  // Verifica si no hay datos de la compañía
  if (!companyInfoData) {
    return null;
  }

  // Desestructurando propiedades de companyInfoData
  const { telefonoWhatsApp, mensajeWhatsApp, textWhatsApp, mapLink, eMail, textEMail } = companyInfoData;

  return (
    <div className="flex flex-row items-center justify-center space-x-1 mb-2">
      {/* Botón de WhatsApp */}
      {telefonoWhatsApp && mensajeWhatsApp && (
        <ContactButton
          onClick={() => {
            const currentUrl = window.location.href;
            const whatsappLink = `https://wa.me/${telefonoWhatsApp}?text=${encodeURIComponent(`${mensajeWhatsApp} ${currentUrl}`)}`;
            window.location.href = whatsappLink;
          }}
          icon={FaWhatsapp}
          text={textWhatsApp}
        />
      )}

      {/* Botón de Llamada */}
      {telefonoWhatsApp && (
        <ContactButton
          onClick={() => {
            const callLink = `tel://+${telefonoWhatsApp}`;
            window.location.href = callLink;
          }}
          icon={SlPhone}
          text="Llamar"
        />
      )}

      {/* Botón de Mapa */}
      {mapLink && (
        <ContactButton
          onClick={() => window.location.href = mapLink}
          icon={SlMap}
          text="Ubicación"
        />
      )}

      {/* Botón de Correo Electrónico */}
      {eMail && isValidEmail(eMail) && (
        <ContactButton
          onClick={() => window.location.href = `mailto:${eMail}`}
          icon={SlEnvelopeOpen}
          text={textEMail}
        />
      )}
    </div>
  );
};

export default ContactButtons;
