import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo

const WhatsappButtonFlotante = () => {
  const companyInfoData = useCompanyInfo();

  if (!companyInfoData) {
    // Si no se pudo cargar la información de la compañía, puedes mostrar un mensaje de carga o manejarlo según tus necesidades
    return <div>Cargando información de la compañía...</div>;
  }

  const startWhatsAppChat = () => {
    const { telefonoWhatsApp, mensajeWhatsApp } = companyInfoData;

    // Revisa si los datos de WhatsApp están disponibles en la información de la compañía
    if (telefonoWhatsApp && mensajeWhatsApp) {
      const sitioWebURL = window.location.href;
      const mensajeConURL = `${mensajeWhatsApp} ${sitioWebURL}`;
      const whatsappURL = `https://api.whatsapp.com/send?phone=${telefonoWhatsApp}&text=${encodeURIComponent(mensajeConURL)}`;

      window.open(whatsappURL, '_blank');
    } else {
      // Maneja el caso en el que no se proporcionen datos de WhatsApp
      console.error('Los datos de WhatsApp no están disponibles en la información de la compañía.');
    }
  };

  return (
    <button onClick={startWhatsAppChat} className='invisible sm:visible flex flex-row font-light leading-none items-center space-x-2 bg bg-green-600 rounded-full py-2 px-3 shadow-md'>
      <p className='ml-2 text-white'>¡Pregúntanos<br/> por <span className="font-semibold text-white">WhatsApp!</span></p>
      <FaWhatsapp size={36} className='text-white' />
    </button>
  );
};

export default WhatsappButtonFlotante;
