import React from 'react';
import Modal from 'react-modal';
import useCompanyInfo from './useCompanyInfo';
import QRCode from 'qrcode.react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaMapPin } from 'react-icons/fa';
import { TfiClose } from "react-icons/tfi";
import { GrSecure } from "react-icons/gr";

Modal.setAppElement('#root');

const CompanyInfoModal = ({ isOpen, onRequestClose }) => {
    const { title, shortDescription, telefonoWhatsApp, eMail, direccion, location } = useCompanyInfo() || {};

    const companyPhone = telefonoWhatsApp || "Teléfono no disponible";
    const companyEmail = eMail || "Correo electrónico no disponible";
    const companyAddress = direccion || "Dirección no disponible";
    const companyLocation = location || "Ubicación no disponible";
    const qrCodeValue = window.location.protocol + '//' + window.location.hostname;

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onRequestClose();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Información de la Compañía"
            className="relative bg-white rounded-lg max-w-lg w-full mx-auto p-6 max-h-[90vh] flex flex-col justify-between transform transition-all sm:w-full animate__animated animate__fadeIn z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center animate__animated animate__fadeIn z-40"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onClick={handleOverlayClick}
        >
            <div className="relative flex-grow overflow-auto">
                <a href='/panel' className='absolute top-1 left-0' target='_blank'>
                    <GrSecure size={22} />
                </a>
                <button
                    onClick={onRequestClose}
                    className="absolute top-1 right-1 bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg"
                    aria-label="Cerrar"
                >
                    <TfiClose className='text-gray-600' size={20} />
                </button>
                <h2 className="ml-8 text-2xl font-semibold text-gray-800 mb-4">
                    {title || "Información no disponible"}
                </h2>
                <p className="text-gray-600 mb-4">{shortDescription || "Descripción no disponible"}</p>

                <div className="text-gray-600 space-y-2 mb-4 border-t border-b py-4 px-4 border-8 rounded-lg">
                    <p className='flex items-center'><FaPhoneAlt className="mr-2" />{companyPhone}</p>
                    <p className='flex items-center'><FaEnvelope className="mr-2" />{companyEmail}</p>
                    <p className='flex items-center'><FaMapMarkerAlt className="mr-2" />{companyAddress}</p>
                    <p className='flex items-center'><FaMapPin className="mr-2" />{companyLocation}</p>
                </div>

                <div className="my-4 flex justify-center">
                    <QRCode value={qrCodeValue} />
                </div>
            </div>
            <div className="mt-6 flex justify-end">
                <button
                    onClick={onRequestClose}
                    className="bg-gray-800 text-white rounded-lg py-2 px-4 hover:bg-gray-700 transition duration-200 focus:outline-none flex items-center"
                >
                    <TfiClose className='mr-2' />
                    Cerrar
                </button>
            </div>
        </Modal>
    );
};

export default CompanyInfoModal;
