// src/components/NotFound.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <h1 className="text-5xl font-bold mb-4">404</h1>
            <p className="text-xl mb-4">Página no encontrada</p>
            <Link to="/" className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Volver a la página principal
            </Link>
        </div>
    );
};

export default NotFound;
