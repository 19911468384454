import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import useCompanyInfo from './useCompanyInfo';
import bancolombiaLogo from '../assets/images/bancolombia.png';
import daviplataLogo from '../assets/images/daviplata.png';
import nequiLogo from '../assets/images/nequi.webp';
import daviviendaLogo from '../assets/images/davivienda.png';
import pseLogo from '../assets/images/pse.png';

Modal.setAppElement('#root');

const TerminosModal = ({ isOpen, onRequestClose }) => {
  const companyInfoData = useCompanyInfo();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [terms, setTerms] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await fetch(`/panel/data/metodospago.json?timestamp=${timestamp}`);
        if (!response.ok) {
          throw new Error('Error al obtener los métodos de pago');
        }
        const data = await response.json();
        setPaymentMethods(data.metodos_de_pago);
      } catch (error) {
        console.error('Error al cargar los métodos de pago:', error);
        setError('Error al cargar los métodos de pago. Por favor, inténtalo de nuevo más tarde.');
      } finally {
        setLoading(false);
      }
    };

    const fetchTerms = async () => {
      try {
        const timestamp = new Date().getTime();
        const response = await fetch(`/panel/data/terminos.json?timestamp=${timestamp}`);
        const data = await response.json();
        setTerms(data.terms);
        setShipping(data.shipping);
      } catch (error) {
        console.error('Error al cargar los términos y condiciones:', error);
        setError('Error al cargar los términos y condiciones. Por favor, inténtalo de nuevo más tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentMethods();
    fetchTerms();
  }, []);

  const getPaymentMethodLogo = (entidad) => {
    const logos = {
      "Bancolombia": bancolombiaLogo,
      "Daviplata": daviplataLogo,
      "Nequi": nequiLogo,
      "Davivienda": daviviendaLogo
    };
    return logos[entidad] || pseLogo;
  };

  if (!companyInfoData) {
    return null;
  }

  const { title, telefonoWhatsApp } = companyInfoData;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Términos y Condiciones"
      className="w-full max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg overflow-auto max-h-full"
      overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
    >
      <div className="relative max-h-full">
        <button
          onClick={onRequestClose}
          className="absolute top-0 right-0 bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
        >
          X
        </button>
        <h2 className="text-2xl font-bold mb-4 border-b pb-1">Términos y Condiciones</h2>
        <div className="space-y-4">
          {terms.map((section, index) => (
            <section key={index}>
              <h3 className="text-lg font-semibold">{section.title}</h3>
              <p className='text-sm' dangerouslySetInnerHTML={{ __html: section.content }}></p>
            </section>
          ))}
        </div>
        <section>
          <h2 className="text-2xl font-bold mb-4 border-b pb-1 mt-6">Políticas de Pagos</h2>
          <div className="space-y-4">
            {loading ? (
              <p className="text-center">Cargando métodos de pago...</p>
            ) : error ? (
              <p className="text-center text-red-500">{error}</p>
            ) : paymentMethods.length > 0 ? (
              <>
                <p className='text-sm'>Nuestros métodos de pago disponibles son los siguientes y pueden ser utilizados de manera segura:</p>
                {paymentMethods.map((method, index) => (
                  <div key={index} className="mb-4 p-2 border rounded-lg shadow-md">
                    <div className='flex justify-between items-center mb-2'>
                      <img
                        src={getPaymentMethodLogo(method.entidad)}
                        alt={`${method.entidad} logo`}
                        className="h-full w-24 mr-2"
                      />
                      <div className='text-start w-full ml-2'>
                        <p className="text-gray-600 text-sm"><strong>Entidad:</strong> {method.entidad}</p>
                        <p className="text-gray-600 text-sm"><strong>Número:</strong> {method.numero_cuenta}</p>
                        <p className="text-gray-600 text-sm"><strong>Tipo:</strong> {method.tipo_cuenta || 'N/A'}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <p className='text-sm'>Todos nuestros métodos de pago garantizan la seguridad de tus datos. Utilizamos pasarelas de pago seguras y tus datos de pago están encriptados para protegerlos contra el acceso no autorizado.</p>
              </>
            ) : (
              <p>Actualmente no hay métodos de pago disponibles publicados. Te invitamos a solicitar el método de pago que prefieras desde el área de contacto o a través del número de WhatsApp de nuestra compañía. Puedes comunicarte con nosotros al siguiente número de WhatsApp: {telefonoWhatsApp}.</p>
            )}
          </div>
        </section>
        <h2 className="text-2xl font-bold mb-4 border-b pb-1 mt-6">Políticas de Envío y Tiempos de Entrega</h2>
        <div className="space-y-4">
          {shipping.map((section, index) => (
            <section key={index}>
              <h3 className="text-lg font-semibold">{section.title}</h3>
              <p className='text-sm' dangerouslySetInnerHTML={{ __html: section.content }}></p>
            </section>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TerminosModal;
