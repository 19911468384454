import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FiShare2 } from 'react-icons/fi';
import useCompanyInfo from './useCompanyInfo';
import { TfiClose } from "react-icons/tfi";
import PayInfoButton from './PayInfoButton';
import bancolombiaLogo from '../assets/images/bancolombia.png';
import daviplataLogo from '../assets/images/daviplata.png';
import nequiLogo from '../assets/images/nequi.webp';
import daviviendaLogo from '../assets/images/davivienda.png';
import pseLogo from '../assets/images/pse.png';

Modal.setAppElement('#root');

const PagosModal = ({ isOpen, onRequestClose }) => {
    const companyInfoData = useCompanyInfo();

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [titular, setTitular] = useState("");
    const [contacto, setContacto] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentMethods = async () => {
            try {
                const timestamp = new Date().getTime(); // Genera un timestamp único
                const response = await fetch(`/panel/data/metodospago.json?timestamp=${timestamp}`);
                if (!response.ok) {
                    throw new Error('Error al obtener los métodos de pago');
                }
                const data = await response.json();
                setPaymentMethods(data.metodos_de_pago);
                setTitular(data.titular);
                setContacto(data.contacto);
            } catch (error) {
                console.error('Error al cargar los métodos de pago:', error);
                setError('Error al cargar los métodos de pago. Por favor, inténtalo de nuevo más tarde.');
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentMethods();
    }, []);

    const sharePaymentMethod = (method) => {
        if (navigator.share) {
            const text = `Método de Pago - ${method.entidad}\n\n` +
                `Número: ${method.numero_cuenta}\n` +
                `Tipo: ${method.tipo_cuenta || 'N/A'}\n` +
                `Titular: ${titular}\n` +
                `Contacto: ${contacto}\n`;

            navigator
                .share({
                    title: `Compartir Método de Pago - ${method.entidad}`,
                    text: text,
                    url: window.location.href,
                })
                .catch((error) => console.error("Error al compartir:", error));
        } else {
            alert("La funcionalidad de compartir no es compatible en este navegador.");
        }
    };

    const getPaymentMethodLogo = (entidad) => {
        const logos = {
            "Bancolombia": bancolombiaLogo,
            "Daviplata": daviplataLogo,
            "Nequi": nequiLogo,
            "Davivienda": daviviendaLogo
        };
        return logos[entidad] || pseLogo;
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onRequestClose();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Métodos de pago"
            className="relative bg-white rounded-lg max-w-lg w-full mx-auto p-6 max-h-[90vh] flex flex-col justify-between transform transition-all sm:w-full animate__animated animate__fadeIn z-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center animate__animated animate__fadeIn z-40"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onClick={handleOverlayClick}
        >
            <div className="relative flex-grow overflow-auto">
                <button
                    onClick={onRequestClose}
                    className="absolute top-1 right-1 bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg"
                    aria-label="Cerrar"
                >
                    <TfiClose className='text-gray-600' size={20} />
                </button>
                <h2 className="text-lg font-semibold text-gray-800 mb-4">
                    Métodos de Pago {companyInfoData ? companyInfoData.title : "Información no disponible"}
                </h2>

                {loading ? (
                    <p className="text-center">Cargando métodos de pago...</p>
                ) : error ? (
                    <p className="text-center text-red-500">{error}</p>
                ) : (
                    <>
                        <div className="mb-4 p-4 rounded-lg shadow bg-gray-50 text-base">
                            <h3 className="text-base font-semibold text-gray-800">Titular</h3>
                            <p className="text-gray-600">{titular}</p>
                            <p className="text-gray-600"><strong>Contacto:</strong> {contacto}</p>
                            <p className='mt-2 text-sm text-gray-600 italic'>Desde el botón compartir puede enviar la información del método de pago que seleccione</p>
                        </div>
                        {paymentMethods.length === 0 ? (
                            <p className="text-center">No hay métodos de pago disponibles.</p>
                        ) : (
                            paymentMethods.map((method, index) => (
                                <div key={index} className="mb-4 p-2 border rounded-lg shadow-md">
                                    <div className='w-full text-end border-b mb-2 pb-2'>
                                        <div className='flex flex-row w-full items-center justify-between px-2'>
                                            <h3 className="text-base font-semibold text-gray-800">
                                                {method.entidad}
                                            </h3>
                                            <button
                                                className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg"
                                                onClick={() => sharePaymentMethod(method)}
                                            >
                                                <FiShare2 size={20} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between items-center mb-2">
                                        <img
                                            src={getPaymentMethodLogo(method.entidad)}
                                            alt={`${method.entidad} logo`}
                                            className="h-full w-24 mr-2"
                                        />
                                        <div className='text-start w-full ml-2'>
                                            <p className="text-gray-600 text-sm"><strong>Número:</strong> {method.numero_cuenta}</p>
                                            <p className="text-gray-600 text-sm"><strong>Tipo:</strong> {method.tipo_cuenta || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                        {companyInfoData && companyInfoData.linkPay && (
                            <div className='mb-4 px-4 py-4 border rounded-lg shadow-md'>
                                <p className='text-md pb-2 font-bold'>Pagos PSE</p>
                                <PayInfoButton />
                            </div>
                        )}
                    </>
                )}
            </div>

            <div className="mt-2 flex justify-start">
                <button
                    onClick={onRequestClose}
                    className="bg-gray-800 text-white rounded-lg py-2 px-4 hover:bg-gray-700 transition duration-200 focus:outline-none flex flex-row items-center"
                >
                    <TfiClose className='mr-3' />
                    Cerrar
                </button>
            </div>
        </Modal>
    );
};

export default PagosModal;
