import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import useCompanyInfo from './useCompanyInfo'; // Ajusta la ruta según la ubicación de tu archivo

function WhatsappButtonProduct({ nombreProducto, descripcionProducto }) {

  // Obtener la información de la compañía usando useCompanyInfo
  const companyInfoData = useCompanyInfo();

  if (!companyInfoData || !companyInfoData.telefonoWhatsApp) {
    // Si no se puede obtener la información de la compañía o el número de teléfono no está disponible, retornar null o manejar el caso según tus necesidades
    return null;
  }
  const numeroTelefono = companyInfoData.telefonoWhatsApp; // Número de teléfono de WhatsApp al que enviar el mensaje
  const mensaje = `¡Hola! Estoy interesado en obtener más información sobre el producto \n\n${nombreProducto}. \n${descripcionProducto}`;

  const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=${numeroTelefono}&text=${encodeURIComponent(mensaje)}`;
    window.open(url, '_blank');
  };

  return (
    <button
    className="bg-gray-50 border hover:bg-gray-100 text-green-600 font-semibold py-2 px-3 rounded-md flex flex-row justify-center items-center space-x-2"
      onClick={handleClick}
    >
       <FaWhatsapp size={28} />
       <div className='font-medium text-sm'>Info</div>
    </button>
  );
}

export default WhatsappButtonProduct;
