import React, { useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import WhatsappButtonProduct from './WhatsappButtonProduct';
import { GrNext, GrPrevious } from 'react-icons/gr';
import useCompanyInfo from './useCompanyInfo';

const ModalProducto = ({ productos, selectedIndex, cerrarModal, agregarAlCarrito, setSelectedIndex }) => {
    const producto = productos[selectedIndex];
    const companyInfo = useCompanyInfo();
    const { descuento, title_sales } = companyInfo || {};

    const handleNextProduct = () => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % productos.length);
    };

    const handlePrevProduct = () => {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + productos.length) % productos.length);
    };

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            cerrarModal();
        }
    };

    const calcularPrecioOferta = (precio, descuento) => {
        return descuento > 0 ? Math.round(precio * (1 + descuento / 100)) : precio;
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                handleNextProduct();
            } else if (event.key === 'ArrowLeft') {
                handlePrevProduct();
            } else if (event.key === 'Escape') {
                cerrarModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    if (!producto || !companyInfo) return null;

    const precioOferta = calcularPrecioOferta(producto.precio, descuento);

    return (
        <div
            className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex justify-center items-center"
            onClick={handleOverlayClick}
        >
            <div className="bg-white rounded-lg max-w-lg mx-auto overflow-y-auto max-h-full sm:max-h-3/4 md:max-h-5/6 lg:max-h-4/5">
                <div className="relative">
                    <button
                        className="absolute top-2 right-2 bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg z-10"
                        onClick={cerrarModal}
                    >
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <img
                        src={`/panel/productos/${producto.new_filename}`}
                        alt={producto.nombre}
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="p-6">
                    <p className="text-gray-500 mt-0 border-b pb-2 mb-4 text-center">{producto.categoria}</p>
                    <h2 className="text-2xl font-semibold mb-2" dangerouslySetInnerHTML={{ __html: producto.nombre }} />
                    <p className="text-gray-700 mb-4" dangerouslySetInnerHTML={{ __html: producto.descripcion }} />
                    <div className="flex justify-center items-center mt-4 text-sm border-t border-b py-4">
                        <div className="flex flex-col items-center w-full">
                            {descuento > 0 ? (
                                <div className="flex flex-col lg:flex-row justify-between items-center w-full">
                                    <div className="flex flex-row items-center space-x-2 lg:space-x-4">
                                        <div className="font-normal text-gray-500 line-through">
                                            $ {precioOferta.toLocaleString()}
                                        </div>
                                        <div className="bg-red-600 text-white px-2 rounded">
                                            -{descuento}%
                                        </div>
                                    </div>
                                    <div className="lg:ml-8 mt-2 lg:mt-0">
                                        <p className="text-gray-900 font-bold text-lg">
                                            <span className="font-normal text-sm pr-2">{title_sales || 'Desde'}</span>
                                            {producto.precio <= 1 ? 'Consultar' : `$${producto.precio.toLocaleString()}`}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-col lg:flex-row justify-between items-center w-full">
                                    <div className="mt-2 lg:mt-0">
                                        <p className="text-gray-900 font-bold text-lg">
                                            <span className="font-normal text-sm pr-2">{title_sales || 'Desde'}</span>
                                            {producto.precio <= 1 ? 'Consultar' : `$${producto.precio.toLocaleString()}`}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-center items-center space-x-3 py-4">
                        <button
                            onClick={() => agregarAlCarrito(producto)}
                            className="bg-gray-950 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded"
                        >
                            <div className="flex items-center space-x-2">
                                <FaShoppingCart size={22} />
                                <span>Agregar al carrito</span>
                            </div>
                        </button>
                        <WhatsappButtonProduct
                            nombreProducto={producto.nombre}
                            descripcionProducto={producto.descripcion}
                        />
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm">
                        <button onClick={handlePrevProduct} className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg">
                            <GrPrevious size={24} />
                        </button>
                        <button onClick={handleNextProduct} className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 hover:text-gray-900 rounded-full p-2 focus:outline-none shadow-lg">
                            <GrNext size={24} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalProducto;
