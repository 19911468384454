
const UrlValidator = ({ url }) => {
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isEmpty = (str) => {
    return !str.trim();
  };

  // Si la URL es nula, está vacía o no es válida, no renderizar nada
  if (!url || isEmpty(url) || !isValidUrl(url)) {
    return null;
  }

  // return <div>La URL es válida</div>;
};

export default UrlValidator;
