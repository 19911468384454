import React from 'react';
import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useCompanyInfo from './components/useCompanyInfo';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Importaciones de componentes
import Navigation from './components/Navigation';
import NotFound from './components/NotFound';
import Home from './pages/Home';
import Empresa from './pages/Empresa';
import Footer from './components/Footer';
import BottomNavigationBar from "./components/BottomNavigationBar";

function App() {
    const companyInfoData = useCompanyInfo();
    const currentPageUrl = window.location.href;

    // Verifica si los datos de la compañía están cargados
    const title = companyInfoData ? companyInfoData.title : 'Título por defecto';
    const description = companyInfoData ? companyInfoData.shortDescription : 'Descripción por defecto';
    const ogImage = companyInfoData ? companyInfoData.ogImageUrl : 'URL de la imagen por defecto'; // Agrega la URL de la imagen predeterminada
    const fbProfileUrl = companyInfoData ? companyInfoData.fbProfileUrl : 'URL del perfil de la empresa en Facebook';
    const twitterImage = companyInfoData ? companyInfoData.twitterImageUrl : 'URL de la imagen por defecto en Twitter'; // Agrega la URL de la imagen predeterminada para Twitter

    return (
        <HelmetProvider>
            <Router>
                <div className="App">
                    {/* Navegación */}
                    <Navigation />

                    {/* Rutas */}
                    <Routes>
                        <Route path="/" element={
                            <>
                                <Helmet>
                                    <title>{title}</title>
                                    <meta name="description" content={description} />
                                    {/* Etiquetas Open Graph para redes sociales */}
                                    <meta property="og:title" content={title} />
                                    <meta property="og:description" content={description} />
                                    <meta property="og:image" content={ogImage} />
                                    <meta property="og:url" content={currentPageUrl} />
                                    <meta property="og:type" content="website" />

                                    {/* Etiquetas para WhatsApp */}
                                    <meta property="og:site_name" content={title} />
                                    <meta property="article:publisher" content={fbProfileUrl} />
                                    <meta property="article:author" content={fbProfileUrl} />

                                    {/* Etiquetas para Telegram */}
                                    <meta property="twitter:card" content="summary_large_image" />
                                    <meta property="twitter:title" content={title} />
                                    <meta property="twitter:description" content={description} />
                                    <meta property="twitter:image" content={twitterImage} />
                                </Helmet>
                                <Home />
                            </>
                        } />
                        <Route path="/empresa" element={<Empresa />} />
                        <Route path="/panel/*" element={<Navigate to="/panel/" />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>

                    {/* Barra de navegación inferior */}
                    <BottomNavigationBar />

                    {/* Pie de página */}
                    <Footer />
                </div>
            </Router>
        </HelmetProvider>
    );
}

export default App;
