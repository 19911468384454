import { useState, useEffect } from 'react';

const useApiStyles = () => {
  const [apiStyles, setApiStyles] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Genera un timestamp único para evitar el caché del navegador
        const timestamp = new Date().getTime();

        // Cargar el archivo empresa.json
        const empresaResponse = await fetch(`/panel/data/empresa.json?timestamp=${timestamp}`);
        if (!empresaResponse.ok) {
          throw new Error('Error al obtener empresa.json');
        }
        const empresaData = await empresaResponse.json();

        // Obtener el color del tema desde empresa.json
        const colorTheme = empresaData.color_theme;

        // Cargar el JSON de estilos basado en el color del tema
        const stylesResponse = await fetch(`/panel/data/useApiStyles.json?timestamp=${timestamp}`);
        if (!stylesResponse.ok) {
          throw new Error('Error al obtener useApiStyles.json');
        }
        const stylesData = await stylesResponse.json();

        // Verificar si el color del tema existe en el JSON de estilos
        if (stylesData[colorTheme]) {
          setApiStyles(stylesData[colorTheme].tailwindClasses);
        } else {
          console.error(`El color del tema "${colorTheme}" no está definido en useApiStyles.json`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return apiStyles;
};

export default useApiStyles;
