import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './Logo';
import useCompanyInfo from './useCompanyInfo';
import { IoIosClose, IoIosMenu, IoIosSearch } from 'react-icons/io';
import { SlWallet } from 'react-icons/sl';
import useApiStyles from './useApiStyles';
import PagosModal from './PagosModal';

// Definir acciones para useReducer
const TOGGLE_MENU = 'TOGGLE_MENU';
const CLOSE_MENU = 'CLOSE_MENU';
const OPEN_PAGOS_MODAL = 'OPEN_PAGOS_MODAL';
const CLOSE_PAGOS_MODAL = 'CLOSE_PAGOS_MODAL';
const SET_VIDEOS_AVAILABLE = 'SET_VIDEOS_AVAILABLE';

// Reducer para manejar el estado
const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, menuOpen: !state.menuOpen };
    case CLOSE_MENU:
      return { ...state, menuOpen: false };
    case OPEN_PAGOS_MODAL:
      return { ...state, pagosModalOpen: true, menuOpen: false };
    case CLOSE_PAGOS_MODAL:
      return { ...state, pagosModalOpen: false };
    case SET_VIDEOS_AVAILABLE:
      return { ...state, videosAvailable: action.payload };
    default:
      return state;
  }
};

const NavigationLink = React.memo(({ href, label, icon, className, onClick }) => (
  <a href={href} className={className} onClick={onClick}>
    {icon || label}
  </a>
));

const MobileMenu = ({ links, themeColors, onLinkClick, onPagosClick, hideLinks }) => (
  <div className={`${themeColors ? themeColors.background : 'bg-gray-900'} md:hidden`}>
    <div className="flex flex-col border-t border-gray-200">
      {links.map(({ href, label, icon }) => (
        !hideLinks.includes(label) && (
          <NavigationLink
            key={href}
            href={href}
            label={label}
            icon={icon}
            className={`block px-8 py-4 text-base font-medium border-b ${themeColors ? themeColors.text : 'text-white'} ${themeColors ? themeColors.hoverBackground : 'hover:bg-gray-700'} ${themeColors ? themeColors.hoverText : 'hover:text-white'}`}
            onClick={onLinkClick}
          />
        )
      ))}
      <button
        className={`block px-8 py-4 text-base font-medium border-b ${themeColors ? themeColors.text : 'text-white'} ${themeColors ? themeColors.hoverBackground : 'hover:bg-gray-700'} ${themeColors ? themeColors.hoverText : 'hover:text-white'}`}
        onClick={onPagosClick}
      >
        <div className="flex items-center">
          <SlWallet size={22} className="mr-2" />
          <span>Pagos</span>
        </div>
      </button>
    </div>
  </div>
);

function Navigation() {
  const companyInfoData = useCompanyInfo();
  const themeColors = useApiStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, {
    menuOpen: false,
    videosAvailable: false,
    pagosModalOpen: false
  });

  const { menuOpen, videosAvailable, pagosModalOpen } = state;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const timestamp = new Date().getTime(); // Generar timestamp
        const response = await fetch(`/panel/data/idvideos.json?timestamp=${timestamp}`);
        const data = await response.json();
        dispatch({ type: SET_VIDEOS_AVAILABLE, payload: data.videos && data.videos.length > 0 });
      } catch (error) {
        console.error('Error fetching videos:', error);
        dispatch({ type: SET_VIDEOS_AVAILABLE, payload: false });
      }
    };

    fetchVideos();
  }, []);

  const toggleMenu = useCallback(() => dispatch({ type: TOGGLE_MENU }), []);
  const closeMenu = useCallback(() => dispatch({ type: CLOSE_MENU }), []);
  const openPagosModal = useCallback(() => dispatch({ type: OPEN_PAGOS_MODAL }), []);
  const closePagosModal = useCallback(() => dispatch({ type: CLOSE_PAGOS_MODAL }), []);

  const { title } = companyInfoData || {};

  const linkClasses = themeColors
    ? `${themeColors.text} ${themeColors.hoverBackground} ${themeColors.hoverText} px-3 py-2 rounded-md text-sm font-medium`
    : 'text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium';

  const handleLinkClick = () => closeMenu();

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavigationClick = (e, href) => {
    e.preventDefault();
    const sectionId = href.replace('#', '');
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => scrollToSection(sectionId), 300); // Ajusta el tiempo si es necesario
    } else {
      scrollToSection(sectionId);
    }
  };

  const baseNavigationLinks = [
    { href: '/', label: 'Inicio' },
    { href: '/empresa', label: 'Empresa' },
    { href: '#productos', label: 'Productos' },
    { href: '#galeria', label: 'Galeria' },
    { href: '#contacto', label: 'Contacto' },
    { href: '#productos', icon: <IoIosSearch size={22} />, label: 'Buscar' },
  ];

  const navigationLinks = baseNavigationLinks.map(link => ({
    ...link,
    onClick: link.href.startsWith('#') ? (e) => handleNavigationClick(e, link.href) : null
  }));

  if (videosAvailable) {
    const galeriaIndex = navigationLinks.findIndex(link => link.label === 'Galeria');
    navigationLinks.splice(galeriaIndex + 1, 0, { href: '#videos', label: 'Videos', onClick: (e) => handleNavigationClick(e, '#videos') });
  }

  const hideLinks = location.pathname !== '/' ? ['Productos', 'Galeria', 'Videos', 'Contacto', 'Buscar'] : [];

  return (
    <>
      <nav className={`${themeColors ? themeColors.background : 'bg-gray-900'} fixed top-0 w-full z-50 opacity-90`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-12">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Logo size={40} />
              <a href="/" className={`ml-4 ${themeColors ? themeColors.text : 'text-white'} font-medium text-base md:text-xl`}>
                {title}
              </a>
            </div>
            <div className="hidden md:flex flex-grow justify-end items-center space-x-4">
              {navigationLinks.map(({ href, label, icon, onClick }) => (
                !hideLinks.includes(label) && (
                  <NavigationLink
                    key={href}
                    href={href}
                    label={label}
                    icon={icon}
                    className={linkClasses}
                    onClick={onClick}
                  />
                )
              ))}
              <button
                className={`flex items-center ${linkClasses}`}
                onClick={openPagosModal}
              >
                <SlWallet size={22} />
                <span className="ml-2">Pagos</span>
              </button>
            </div>
            <div className="flex md:hidden">
              <button
                onClick={toggleMenu}
                className={`text-gray-300 hover:text-white inline-flex items-center justify-center p-2 rounded-md focus:outline-none ${themeColors ? themeColors.text : ''} ${themeColors ? themeColors.hoverText : ''}`}
                aria-label="Menu"
                aria-expanded={menuOpen}
              >
                {menuOpen ? <IoIosClose size={30} /> : <IoIosMenu size={30} />}
              </button>
            </div>
          </div>
        </div>
        {menuOpen && (
          <MobileMenu
            links={navigationLinks}
            themeColors={themeColors}
            onLinkClick={handleLinkClick}
            onPagosClick={openPagosModal}
            hideLinks={hideLinks}
          />
        )}
      </nav>
      <PagosModal isOpen={pagosModalOpen} onRequestClose={closePagosModal} />
    </>
  );
}

export default Navigation;
